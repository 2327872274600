<template>
    <div>
        <AppLoadingWrapper v-if="loading" />
        <DataTable
            v-if="!loading"
            dataKey="id"
            :value="records"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            v-model:expandedRows="expandedRows"
        >
            <template #empty>
                <div class="flex flex-column justify-content-center align-items-center p-2">
                    <span style="font-size: 2rem; color: var(--gray-500);">Nenhum dado encontrado.</span>
                </div>
            </template>

            <Column v-if="expander" :expander="true" headerStyle="width: 3rem" />

            <slot name="columns"></slot>

            <template v-if="expander" #expansion="slotProps">
                <slot name="expansion" v-bind:data="slotProps?.data"></slot>
            </template>
        </DataTable>
    </div>
</template>

<script>
import { montarFiltrosExtras } from '../../../utils/FiltrosExtras';
import { showError } from '../../../utils/Toast';
export default {
    props: {
        service: {
            type: Object,
            required: true
        },
        filtrosExtras: {
            type: Object
        },
        filter: {
            type: Object
        },
        expander: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            records: [],
            expandedRows: [],
            filtrosExtrasLocal: {}
        }
    },
    watch:{
        'filtrosExtras'(newValue){
            this.filtrosExtrasLocal = montarFiltrosExtras(newValue);
            this.load(this.filtrosExtrasLocal);
        }
    },
    mounted() {
        this.$service = this.service;
        this.filtrosExtrasLocal = montarFiltrosExtras(this.filtrosExtras);
    },
    methods: {
        async load(filters = null) {
            try {
                this.loading = true;
                const { data } = await this.$service.findAll({ filter: this.filter, filtrosExtras: filters ?? this.filtrosExtrasLocal });
                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        }
    }
}
</script>

<style>

</style>
