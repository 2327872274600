<template>
    <div>
        <SimpleGrid ref="grid" :service="service" :filtrosExtras="filtrosExtras">
            <template #columns>
                <Column header="Perfil / Agrupamento">
                    <template #body="slotProps">
                        <div class="truncate-text">
                            {{
                                slotProps?.data?.inventarioRisco?.perfilExposicaoId
                                    ? slotProps?.data?.inventarioRisco?.perfilExposicao?.nome
                                    : slotProps?.data?.inventarioRisco?.identificacaoAgrupamento
                            }}
                        </div>
                    </template>
                </Column>
                <Column field="hierarquia.cliente.grupo.descricao" header="Grupo" />
                <Column field="hierarquia.cliente.name" header="Cliente" />
                <Column field="hierarquia.customerBranch.name" header="Unidade" />
                <Column field="hierarquia.customerDepartment.name" header="Setor" />
                <Column field="hierarquia.customerPosition.name" header="Cargo" />
                <Column header="Atividade específica" />
                <Column>
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-sm p-button-outlined" @click="openDialog(slotProps)" />
                    </template>
                </Column>
            </template>
        </SimpleGrid>

        <Dialog :visible="isOpen" class="p-fluid" :style="{ width: '450px' }" header="Remover hierarquia" :modal="true">
            Deseja remover a hierarquia do inventário?
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="onCloseDialog" />
                <Button :loading="loadingRemoverHierarquia" label="Sim" icon="pi pi-check" autofocus @click="onConfirmDialog" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import InventarioHierarquiaService from '../services/InventarioHierarquiaService';
import SimpleGrid from '../components/SimpleGrid.vue';
import { showError, showSuccess } from '../../../utils/Toast';

export default {
    props: {
        baseRoute: {
            type: String
        }
    },
    components: {
        SimpleGrid
    },
    data() {
        return {
            service: null,
            loadingRemoverHierarquia: false,
            isOpen: false,
            rowData: {},
            filtrosExtras: {
                where: []
            }
        };
    },
    created() {
        this.service = new InventarioHierarquiaService();
    },
    mounted() {
        this.load();
    },
    methods: {
        openDialog(data) {
            this.isOpen = true;
            this.rowData = data;
        },
        onCloseDialog() {
            this.isOpen = false;
        },
        async onConfirmDialog() {
            try {
                this.loadingRemoverHierarquia = true;
                const { id } = this.rowData.data;
                await this.service.remove(id);
                showSuccess(this.$toast, 'Hierarquia removida!');
                this.rowData = {};
                this.loadingRemoverHierarquia = false;
                this.onCloseDialog();
                await this.load();
                if (!this.$refs.grid.records?.length) {
                    this.$router.push('/security/inventario-risco2');
                }
                this.$emit('onAfterConfirmDialog');
            } catch (error) {
                this.loadingRemoverHierarquia = false;
                showError(this.$toast, error);
            }
        },
        async load(id = this.$route.params.id) {
            if (!id) return;
            this.filtrosExtras.where = [];
            this.filtrosExtras.where.push({
                prop: 'inventarioRisco.id',
                operator: 'equal',
                values: [id]
            });
            await this.$refs?.grid?.load();
        }
    }
};
</script>

<style>
.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Defina a largura máxima da célula conforme necessário */
}

.truncate-text:hover {
    white-space: normal;
    overflow: visible;
    max-width: none;
}
</style>
