export function montarFiltrosExtras(filtrosExtras, mapFilters = null) {
    if (!filtrosExtras) return null;

    if (!filtrosExtras?.where) {
        return filtrosExtras = { where: [] };
    }

    filtrosExtras.where = filtrosExtras?.where?.map(p => {
        return {
            operator: p.operator,
            prop: mapFilters?.find(q => q.filter == p.prop)?.value ?? p.prop,
            values: p.values,
            ilikeAny: p.ilikeAny
        }
    });

    return filtrosExtras;
}
